import Anchor from '@/components/shared/Anchor/Anchor';
import Button from '@/components/base/elements/Button/Button';
import {useTranslations} from '@/hooks/useTranslations';
import {
  PARTNERS_LOGIN_URL,
  PARTNERS_SIGNUP_URL,
} from '@/components/pages/partners/blog/constants';

export const PartnersCtaLinkList = () => {
  const {t, localizePath} = useTranslations();

  return (
    <>
      <li className="mr-8 text-base">
        <Anchor
          className="whitespace-nowrap hover:underline text-black"
          href={localizePath('')}
          componentName="shopify site"
        >
          {t('nav:shopifySite')}
        </Anchor>
      </li>
      <li className="mr-8 text-base">
        <Anchor
          className="whitespace-nowrap hover:underline text-black"
          href={PARTNERS_LOGIN_URL}
          componentName="login"
        >
          {t('nav:login')}
        </Anchor>
      </li>
      <li className="leading-[0]">
        <Button
          href={PARTNERS_SIGNUP_URL}
          mode="light"
          size="small"
          componentName="sign up"
        >
          {t('nav:joinNow')}
        </Button>
      </li>
    </>
  );
};

export const PartnersMobileCtaLinkList = () => {
  const {t} = useTranslations();

  return (
    <>
      <li className="mr-2 sm:mr-8 text-base">
        <Anchor
          className="whitespace-nowrap hover:underline text-black"
          href={PARTNERS_SIGNUP_URL}
          componentName="shopify site"
        >
          {t('nav:joinNow')}
        </Anchor>
      </li>
    </>
  );
};

export const PartnersMobileMenuCtaLinkList = () => {
  const {t} = useTranslations();

  return (
    <>
      <li className="py-1 sm:grow sm:pt-2">
        <Button
          href={PARTNERS_LOGIN_URL}
          intent="secondary"
          className="w-full"
          mode="light"
          componentName="login"
        >
          {t('nav:login')}
        </Button>
      </li>
      <li className="pt-1 sm:grow sm:pt-2">
        <Button
          href={PARTNERS_SIGNUP_URL}
          className="w-full"
          mode="light"
          componentName="sign up"
        >
          {t('nav:joinNow')}
        </Button>
      </li>
    </>
  );
};
