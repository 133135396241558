import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function ptBRSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: 'Blog',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: 'Menu',
    overviewText: 'Página inicial',
    links: [
      {
        text: 'Veja mais ideias',
        url: blogPath('/topics/ideias-de-neg%C3%B3cios', site),
      },
      {
        text: 'Como vender online',
        url: blogPath('/topics/como-vender-pela-internet', site),
      },
      {
        text: 'Mais recentes',
        url: blogPath('/latest', site),
      },
      {
        text: 'Mais',
        links: [
          {
            text: 'Redes sociais',
            url: blogPath('/topics/redes-sociais', site),
          },
          {
            text: 'Branding',
            url: blogPath('/topics/branding', site),
          },
          {
            text: 'Como montar uma loja virtual',
            url: blogPath('/topics/como-montar-uma-loja-virtual', site),
          },
          {
            text: 'Como abrir um negócio',
            url: blogPath('/topics/como-abrir-um-neg%C3%B3cio', site),
          },
          {
            text: 'E-commerce',
            url: blogPath('/topics/ecommerce', site),
          },
          {
            text: 'Ver todos os tópicos',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
