import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {Site} from '@data/types';

import type {BlogSubNavVariants, SubNav} from './types';

export function enSubNav(
  t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
  variant?: string,
) {
  const variants: BlogSubNavVariants = {
    dropshipping: {
      links: [
        {
          text: t('subNav.variants.dropshipping.links.dropshipping'),
          url: blogPath('/what-is-dropshipping', site),
        },
        {
          text: t('subNav.variants.dropshipping.links.start'),
          url: blogPath('/how-to-start-dropshipping', site),
        },
        {
          text: t('subNav.variants.dropshipping.links.suppliers'),
          url: blogPath('/dropshipping-suppliers', site),
        },
        {
          text: t('subNav.variants.dropshipping.links.guides'),
          url: blogPath('/dropshipping-guide', site),
        },

        {
          text: t('subNav.variants.dropshipping.links.more'),
          links: [
            {
              text: t('subNav.variants.dropshipping.subLinks.products'),
              url: blogPath('/best-dropshipping-products', site),
            },
            {
              text: t('subNav.variants.dropshipping.subLinks.apps'),
              url: 'https://apps.shopify.com/categories/finding-products-product-sourcing-dropshipping',
            },
            {
              text: t('subNav.variants.dropshipping.subLinks.tips'),
              url: blogPath('/dropshipping-success', site),
            },
            {
              text: t('subNav.variants.dropshipping.subLinks.videos'),
              url: 'https://www.youtube.com/playlist?list=PL1BMWTNEcBEoVNKsBNjKY7r4PfhwisM-L',
            },
            {
              text: t('subNav.variants.dropshipping.subLinks.help'),
              url: 'https://help.shopify.com/en/manual/products/dropshipping',
            },
          ],
        },
      ],
    },
  };

  return () =>
    ({
      sectionTitle: t('subNav.title'),
      sectionOverviewUrl: localizePath(enBlogPath),
      pageTitle: t('subNav.pageTitle'),
      overviewText: t('subNav.overviewText'),
      links: [
        {
          text: t('subNav.links.findIdea'),
          url: blogPath('/topics/find-an-idea', site),
        },
        {
          text: t('subNav.links.starting'),
          url: blogPath('/topics/starting-up', site),
        },
        {
          text: t('subNav.links.marketing'),
          url: blogPath('/topics/marketing', site),
        },
        {
          text: t('subNav.links.latest'),
          url: blogPath('/latest', site),
        },
        {
          text: t('subNav.links.moreTopics'),
          links: [
            {
              text: t('subNav.subLinks.guides'),
              url: blogPath('/topics/guides', site),
            },
            {
              text: t('subNav.subLinks.shopify-master'),
              url: blogPath('/topics/podcasts', site),
            },
            {
              text: t('subNav.subLinks.founder-stories'),
              url: blogPath('/topics/founder-stories', site),
            },
            {
              text: t('subNav.subLinks.how-to-sell-online'),
              url: blogPath('/topics/sell-online', site),
            },
            {
              text: t('subNav.subLinks.see-all-topics'),
              url: blogPath('/topics', site),
            },
            {
              text: t('subNav.subLinks.enterprise-blog'),
              url: localizePath('/enterprise/blog'),
            },
          ],
        },
      ],
      ...(variant && variants[variant]),
    }) satisfies SubNav;
}
