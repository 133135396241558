import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function frSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: 'Blog',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: 'Menu',
    overviewText: "Page d'acceuil",
    links: [
      {
        text: 'Créer son activité',
        url: blogPath('/topics/monter-une-entreprise', site),
      },
      {
        text: 'Idées E-commerce',
        url: blogPath('/topics/inspiration-e-commerce', site),
      },
      {
        text: 'Nouveautés',
        url: blogPath('/latest', site),
      },
      {
        text: 'Plus',
        links: [
          {
            text: 'Marketing',
            url: blogPath('/topics/marketing', site),
          },
          {
            text: 'Success story',
            url: blogPath('/topics/success-story', site),
          },
          {
            text: 'Vendre en ligne',
            url: blogPath('/topics/vendre-en-ligne', site),
          },
          {
            text: 'Trouver des produits à vendre',
            url: blogPath('/topics/trouver-des-produits-%C3%A0-vendre', site),
          },
          {
            text: 'E-commerce',
            url: blogPath('/topics/e-commerce', site),
          },
          {
            text: 'Voir tous les sujets',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
