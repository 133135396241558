import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function jaSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: 'ブログ',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: 'メニュー',
    overviewText: 'ホームページ',
    links: [
      {
        text: 'ビジネスの始め方',
        url: blogPath(
          '/topics/%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%81%AE%E5%A7%8B%E3%82%81%E6%96%B9',
          site,
        ),
      },
      {
        text: 'マーケティング',
        url: blogPath(
          '/topics/%E3%83%9E%E3%83%BC%E3%82%B1%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0',
          site,
        ),
      },
      {
        text: '最新',
        url: blogPath('/latest', site),
      },
      {
        text: 'さらに表示',
        links: [
          {
            text: 'アイデア',
            url: blogPath('/topics/%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2', site),
          },
          {
            text: '越境EC',
            url: blogPath('/topics/%E8%B6%8A%E5%A2%83ec', site),
          },
          {
            text: '設定',
            url: blogPath('/topics/%E8%A8%AD%E5%AE%9A', site),
          },
          {
            text: '事例',
            url: blogPath('/topics/%E4%BA%8B%E4%BE%8B', site),
          },
          {
            text: 'ストアの作成方法',
            url: blogPath(
              '/topics/%E3%82%B9%E3%83%88%E3%82%A2%E3%81%AE%E4%BD%9C%E6%88%90%E6%96%B9%E6%B3%95',
              site,
            ),
          },
          {
            text: 'すべてのトピックを表示',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
