import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function zhCNSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: '博客',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: '菜单',
    overviewText: '主页',
    links: [
      {
        text: '新手上路',
        url: blogPath('/topics/start-online-store', site),
      },
      {
        text: '营销指南',
        url: blogPath('/topics/marketing', site),
      },
      {
        text: '最新文章',
        url: blogPath('/latest', site),
      },
      {
        text: '更多',
        links: [
          {
            text: '成功案例',
            url: blogPath('/topics/case-study', site),
          },
          {
            text: '平台动态',
            url: blogPath('/topics/updates', site),
          },
          {
            text: '合作伙伴',
            url: blogPath('/topics/partners', site),
          },
          {
            text: '选品攻略',
            url: blogPath('/topics/source-products', site),
          },
          {
            text: '查看所有主题',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
