import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function esSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: 'Blog',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: 'Menú',
    overviewText: 'Página de inicio',
    links: [
      {
        text: 'Cómo vender online',
        url: blogPath('/topics/c%C3%B3mo-vender-online', site),
      },
      {
        text: 'Emprender',
        url: blogPath('/topics/emprender', site),
      },
      {
        text: 'Más reciente',
        url: blogPath('/latest', site),
      },
      {
        text: 'Más',
        links: [
          {
            text: 'Dropshipping',
            url: blogPath('/topics/dropshipping', site),
          },
          {
            text: 'Comercio en línea',
            url: blogPath('/topics/ecommerce', site),
          },
          {
            text: 'Redes sociales',
            url: blogPath('/topics/social-media', site),
          },
          {
            text: 'Tutoriales de Shopify',
            url: blogPath('/topics/tutoriales-de-shopify', site),
          },
          {
            text: 'Marketing',
            url: blogPath('/topics/marketing', site),
          },
          {
            text: 'Todos los temas',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
