import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function deSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: 'Blog',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: 'Menü',
    overviewText: 'Startseite',
    links: [
      {
        text: 'Onlineshop starten',
        url: blogPath('/topics/onlineshop-starten', site),
      },
      {
        text: 'Dropshipping',
        url: blogPath('/topics/dropshipping', site),
      },
      {
        text: 'Neueste',
        url: blogPath('/latest', site),
      },
      {
        text: 'Mehr',
        links: [
          {
            text: 'Online-Marketing',
            url: blogPath('/topics/online-marketing', site),
          },
          {
            text: 'Erfolgreiche Händler',
            url: blogPath('/topics/erfolgreiche-h%C3%A4ndler', site),
          },
          {
            text: 'Social Media Marketing',
            url: blogPath('/topics/social-media-marketing', site),
          },
          {
            text: 'Content Marketing',
            url: blogPath('/topics/content-marketing', site),
          },
          {
            text: 'Entrepreneurship',
            url: blogPath('/topics/entrepreneurship', site),
          },
          {
            text: 'Alle Themen anzeigen',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
