import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function krSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: '블로그',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: '메뉴',
    overviewText: '홈페이지',
    links: [
      {
        text: '비즈니스 시작하기',
        url: blogPath('/topics/start-your-business', site),
      },
      {
        text: '비즈니스 아이디어 찾기',
        url: blogPath('/topics/find-a-business-idea', site),
      },
      {
        text: '온라인으로 수익 창출',
        url: blogPath('/topics/make-money-online', site),
      },
      {
        text: '최신 정보',
        url: blogPath('/latest', site),
      },
      {
        text: '더 보기',
        links: [
          {
            text: '마케팅',
            url: blogPath('/topics/marketing', site),
          },
          {
            text: '모든 주제 보기',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
