import {helpPartnersPath} from '@/hooks/navigation/utils';
import type {NavigationListItem, I18NOptions} from '@/types';

export const getPartnersMenuItems = ({
  t,
  site,
}: I18NOptions): NavigationListItem[] => {
  const gaEvent = 'Main Nav';
  return [
    {
      content: t('nav:docs'),
      url: helpPartnersPath(site),
      data: {
        gaEvent,
        gaAction: 'Docs',
      },
    },
    {
      content: t('nav:community'),
      url: 'https://community.shopify.com/c/partners-and-developers/ct-p/appdev',
      data: {
        gaEvent,
        gaAction: 'Community',
      },
    },
    {
      content: t('nav:academy'),
      url: 'https://academy.shopify.com',
      data: {
        gaEvent,
        gaAction: 'Academy',
      },
    },
  ];
};
