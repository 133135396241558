import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function itSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: 'Blog',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: 'Menu',
    overviewText: 'Homepage',
    links: [
      {
        text: 'Imprenditoria',
        url: blogPath('/topics/imprenditoria', site),
      },
      {
        text: 'Cosa vendere online',
        url: blogPath('/topics/cosa-vendere-online', site),
      },
      {
        text: 'Ultime notizie',
        url: blogPath('/latest', site),
      },
      {
        text: 'Altro',
        links: [
          {
            text: 'Social Media Marketing',
            url: blogPath('/topics/social-media-marketing', site),
          },
          {
            text: 'Produttività',
            url: blogPath('/topics/produttivit%C3%A0', site),
          },
          {
            text: 'ecommerce',
            url: blogPath('/topics/ecommerce', site),
          },
          {
            text: 'Branding',
            url: blogPath('/topics/branding', site),
          },
          {
            text: 'Ispirazione',
            url: blogPath('/topics/ispirazione', site),
          },
          {
            text: 'Tutti gli argomenti',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
