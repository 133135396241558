import {type HydratedT} from '@/components/shared/Page/I18N';
import type {LinkComponent} from '@/types';

export const retailBlogSubNav = (
  t: HydratedT,
  localizePath: (path: string) => string,
  enBlogPath: string,
) => {
  return () => ({
    sectionTitle: t('subNav.title'),
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: t('subNav.pageTitle'),
    overviewText: t('subNav.overviewText'),
    links: [
      {
        text: t('subNav.links.manageInventory'),
        url: localizePath(`${enBlogPath}topics/inventory-management`),
        name: 'manage-inventory',
      },
      {
        text: t('subNav.links.openStore'),
        url: localizePath(`${enBlogPath}topics/open-a-retail-store`),
        name: 'open-store',
      },
      {
        text: t('subNav.links.latest'),
        url: localizePath(`${enBlogPath}latest`),
        name: 'latest',
      },
      {
        text: t('subNav.links.more'),
        name: 'more',
        links: [
          {
            text: t('subNav.subLinks.profitability'),
            url: localizePath(`${enBlogPath}topics/profitability`),
            name: 'profitability',
          },
          {
            text: t('subNav.subLinks.paymentsCheckout'),
            url: localizePath(`${enBlogPath}topics/payments-checkout`),
            name: 'payments-checkout',
          },
          {
            text: t('subNav.subLinks.merchandisingStoreLayout'),
            url: localizePath(`${enBlogPath}topics/merchandising-store-layout`),
            name: 'merchandising-store-layout',
          },
          {
            text: t('subNav.subLinks.storeManagement'),
            url: localizePath(`${enBlogPath}topics/store-management`),
            name: 'store-management',
          },
          {
            text: t('subNav.links.marketing'),
            url: localizePath(`${enBlogPath}topics/marketing`),
            name: 'marketing',
          },
          {
            text: t('subNav.subLinks.seeAllTopics'),
            url: localizePath(`${enBlogPath}topics`),
            name: 'see-all-topics',
          },
        ],
      },
    ] as LinkComponent[],
  });
};
