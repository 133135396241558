import {type HydratedT} from '@/components/shared/Page/I18N';
import type {LinkComponent} from '@/types';

export const partnersBlogSubNav = (
  t: HydratedT,
  localizePath: (path: string) => string,
  enBlogPath: string,
) => {
  return () => ({
    sectionTitle: t('subNav.title'),
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: t('subNav.pageTitle'),
    overviewText: t('subNav.overviewText'),
    links: [
      {
        text: t('subNav.links.shopifyNews'),
        url: localizePath(`${enBlogPath}topics/shopify-news`),
        name: 'shopify-news',
      },
      {
        text: t('subNav.links.caseStudies'),
        url: localizePath(`${enBlogPath}topics/case-studies`),
        name: 'case-studies',
      },
      {
        text: t('subNav.links.latest'),
        url: localizePath(`${enBlogPath}latest`),
        name: 'latest',
      },
      {
        text: t('subNav.links.more'),
        name: 'more',
        links: [
          {
            text: t('subNav.subLinks.appDevelopment'),
            url: localizePath(`${enBlogPath}topics/shopify-app-development`),
            name: 'shopify-app-development',
          },
          {
            text: t('subNav.subLinks.shopifyThemeDevelopment'),
            url: localizePath(`${enBlogPath}topics/shopify-theme-development`),
            name: 'shopify-theme-development',
          },
          {
            text: t('subNav.subLinks.frontEndDevelopment'),
            url: localizePath(`${enBlogPath}topics/front-end-development`),
            name: 'front-end-development',
          },
          {
            text: t('subNav.subLinks.inspirationCreativity'),
            url: localizePath(`${enBlogPath}topics/inspiration-creativity`),
            name: 'inspiration-creativity',
          },
          {
            text: t('subNav.subLinks.findingNewClients'),
            url: localizePath(`${enBlogPath}topics/finding-new-clients`),
            name: 'finding-new-clients',
          },
          {
            text: t('subNav.subLinks.seeAllTopics'),
            url: localizePath(`${enBlogPath}topics`),
            name: 'see-all-topics',
          },
        ],
      },
    ] as LinkComponent[],
  });
};
