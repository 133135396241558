import {type HydratedT} from '@/components/shared/Page/I18N';
import {blogPath} from '@/hooks/navigation/utils';
import type {LinkComponent} from '@/types';
import type {Site} from '@data/types';

export function nlSubNav(
  _t: HydratedT,
  localizePath: (path: string) => string,
  site: Site,
  enBlogPath: string,
) {
  return () => ({
    sectionTitle: 'Blog',
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: 'Menu',
    overviewText: 'Homepage',
    links: [
      {
        text: 'Ideeën voor een webshop',
        url: blogPath('/topics/product-idee%C3%ABn-webshop-beginnen', site),
      },
      {
        text: 'Marketing',
        url: blogPath('/topics/marketing', site),
      },
      {
        text: 'Nieuwste',
        url: blogPath('/latest', site),
      },
      {
        text: 'Meer',
        links: [
          {
            text: 'Inspiratie',
            url: blogPath('/topics/inspiratie', site),
          },
          {
            text: 'Dropshipping',
            url: blogPath('/topics/dropshipping', site),
          },
          {
            text: 'Sociale Media',
            url: blogPath('/topics/sociale-media', site),
          },
          {
            text: 'Succesverhalen',
            url: blogPath('/topics/succesverhalen', site),
          },
          {
            text: 'Bedrijfsvoering',
            url: blogPath('/topics/bedrijfsvoering', site),
          },
          {
            text: 'Alle onderwerpen',
            url: blogPath('/topics', site),
          },
        ],
      },
    ] as LinkComponent[],
  });
}
